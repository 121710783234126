import { defineComponent, onUnmounted, onMounted, ref } from 'vue'
import { useSnapCarousel } from '@/composables/useSnapSlider'

export default defineComponent({
  setup(_, { slots }) {
    const sliderRef = ref(null)

    const {
      snapSlider,
      init,
      checkAvailability,
      isAvailable,
      isHasPrev,
      isHasNext,
      nextSlide,
      prevSlide,
    } = useSnapCarousel(sliderRef)

    onMounted(() => {
      init({ current: 1 })
      checkAvailability(sliderRef.value)
    })
    onUnmounted(() => {
      snapSlider.value?.destroy?.()
    })

    return () =>
      slots.default?.({
        sliderRef,
        isAvailableScroll: isAvailable.value,
        isHasPrev: isHasPrev.value,
        isHasNext: isHasNext.value,
        prevSlide: prevSlide,
        nextSlide: nextSlide,
      })
  },
})
